import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Markdown from "react-markdown";
import Layout from "../components/layout";
import SEO from "../components/seo";


const ProductsServices = () => {
    const data = useStaticQuery(query);

    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <SEO title="Products & Services" keywords={[`gatsby`, `react`, `bootstrap`]} />
            <div className="container">
                <div className="row pt-5">
                    <div className="col-md-12 gPadding">
                        <div className="display-3">{data.strapiProducts.title}</div>
                        <div className="a2 about">{data.strapiProducts.subtitle}</div>
                        <hr></hr>
                    </div>
                </div>
                <div className="row pt-5 separatorDown">
                    <div className="col-md-6 gPadding pb-4 pb-md-0">
                        <div className="a1">{data.strapiProducts.paragraph1_title}</div>
                        <Markdown source={data.strapiProducts.paragraph1_description} className="py-2"></Markdown>
                    </div>
                    <div className="col-md-6 crop-height gPadding">
                        <img src={data.strapiProducts.paragraph1_picture.publicURL} className="scale" alt="Group People" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 offset-md-1 crop-height d-none d-md-block">
                        <img src={data.strapiProducts.paragraph2_picture.publicURL} className="scale" alt="Group People" />
                    </div>
                    <div className="intro col-md-7 gPadding" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <div className="a1">{data.strapiProducts.paragraph2_title}</div>
                        <Markdown source={data.strapiProducts.paragraph2_description} className="py-2"></Markdown>
                    </div>
                    <div className="col-md-6 crop-height gPadding d-md-none pt-3">
                        <img src={data.strapiProducts.paragraph2_picture.publicURL} className="scale" alt="Group People" />
                    </div>
                </div>
                <div className="row separatorUp" >
                    <div className="col-md-8 gPadding">
                        <div className="a1">{data.strapiProducts.paragraph3_title}</div>
                        <Markdown source={data.strapiProducts.paragraph3_description} className="py-2"></Markdown>
                    </div>
                    <div className="col-md-4 crop-height" style={{ paddingTop: '25px' }}>
                        <img src={data.strapiProducts.paragraph3_picture.publicURL} className="scale" alt="Group People" />
                    </div>
                </div>


            </div >
        </Layout>
    );
};

const query = graphql`
  query {
    strapiProducts {
        title
        subtitle
        paragraph1_title
        paragraph1_description
        paragraph1_picture {
        publicURL
        }
        paragraph2_title
        paragraph2_description
        paragraph2_picture {
        publicURL
        }
        paragraph3_title
        paragraph3_description
        paragraph3_picture {
        publicURL
        }
      }
  }
`;

export default ProductsServices;
